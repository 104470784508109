import { setLoggedIn } from '@/auth/authSlice';
import { hasAnyRoles } from '@/auth/helpers';
import { Error, Loading, getApi } from '@/common';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import { LayoutContext } from '@/common/layoutContext';
import { fetchDashboardConfiguration } from '@/dashboardConfiguration/dashboardSlice';
import Card from '@mui/material/Card';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

export const MobileReport = () => {
  const dispatch = useDispatch();
  const { key } = useParams('key');
  const { search } = useLocation();
  const [error, setError] = useState();
  const setLayout = useContext(LayoutContext);

  const token = new URLSearchParams(search).get('token');

  const { configuration, loading } = useSelector(
    (state) => state.dashboardConfig
  );

  const { user, isLoading } = useSelector((state) => state.auth);

  const conf = useMemo(() => {
    for (const conf of configuration) {
      if (conf.key === key && conf?.value?.isMobile) {
        return conf;
      }
    }
  }, [key, configuration]);

  useEffect(() => {
    setLayout({ disableGutters: true, maxWidth: false });
    if (token && !isLoading && !user) {
      getApi()
        .get('/api/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (!res?.data) {
            throw new Error(`User can't view this report!`);
          }
          const payload = { user: res?.data, idToken: token };
          dispatch(setLoggedIn(payload));
          dispatch(fetchDashboardConfiguration());
        })
        .catch((error) => {
          setError(`${error}`);
        });
    } else if (!token && !error) {
      setError(`Invalid report loaded!`);
    }
  }, [dispatch, token, user, isLoading, error]);

  const userRoles = user?.role ?? '';
  const position = (user?.fieldAgent?.positionId ?? '') + '';
  const requiredRoles = (conf?.roles ?? []).join(',');
  const requiredPositions = (conf?.positions ?? []).map((p) => p?.id).join(',');
  const isAllowed =
    conf &&
    hasAnyRoles(userRoles, requiredRoles) &&
    hasAnyRoles(position, requiredPositions);

  return (
    <>
      {loading || isLoading || (configuration && !conf) ? (
        <Loading />
      ) : !conf ? (
        <Card>
          <Error error="Report not found!" />
        </Card>
      ) : !isAllowed ? (
        <Card>
          <Error error="You don't have required permissions!" />
        </Card>
      ) : error ? (
        <Card>
          <Error error={error} />
        </Card>
      ) : (
        <EmbeddedChart theKey={key} />
      )}
    </>
  );
};
