import { AppBar, Error, Loading, useInitial } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fieldAgentListFetch } from './fieldAgentListSlice';
import { fieldAgentReassignSave } from './fieldAgentReassignFormSlice';
import { fieldAgentFetch } from './fieldAgentSlice';
import { captureEvent } from '@/firebase';

export const FieldAgentReassignForm = () => {
  // console.log('RXD:FieldAgentReassignForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const {
    fieldAgent,
    isLoading: fieldAgentIsLoading,
    error: fieldAgentError,
  } = useSelector((state) => state.fieldAgent);

  const { error: formError, status: formStatus } = useSelector(
    (state) => state.fieldAgentReassignForm
  );

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const [newFieldAgent, setNewFieldAgent] = useState('');
  const [comment, setComment] = useState('');

  // initial data
  useInitial(() => {
    // console.log('RXD:FieldAgentReassignForm: initial');
    if (uuid !== fieldAgent?.uuid) {
      dispatch(fieldAgentFetch(uuid));
    }
  });

  const filterFieldAgents = useCallback(
    (value, pp) => {
      // console.log('RXD:FieldAgentReassignForm', { value, pp });
      const query = new URLSearchParams('oo=name&ff=fa.uuid,fa.name');
      query.set('pp', pp || '1:20');
      query.set('name', `contains:${value}`);
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch]
  );

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(fieldAgentReassignSave(uuid, newFieldAgent.uuid, { comment }));
    captureEvent('FIELD_AGENT_MOVE_FORM_SAVE', {});
  };

  return (
    <>
      <AppBar>Move agents</AppBar>

      {formError && <Error error={formError} />}

      <Card>
        <CardContent>
          {fieldAgentError ? (
            <Error error={fieldAgentError} />
          ) : fieldAgentIsLoading ? (
            <Loading />
          ) : (
            <Box
              component="form"
              method="POST"
              sx={{ p: 1 }}
              onSubmit={handleSave}
              autoComplete="off"
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <Alert severity="info" sx={{ p: 1 }}>
                    Move all agents ({fieldAgent.totalAgents}) currently managed
                    by "<strong>{fieldAgent.name}</strong>" to the one selected
                    below:
                  </Alert>

                  <DynamicAutoComplete
                    controlProps={{ sx: { my: 2 } }}
                    options={fieldAgentList.filter((x) => x.uuid !== uuid)}
                    multiple={false}
                    isOptionEqualToValue={(option, value) =>
                      option?.uuid === value?.uuid
                    }
                    getOptionLabel={(option) => option?.name ?? ''}
                    handleSearch={filterFieldAgents}
                    renderOption={(props, fieldAgent) => (
                      <li {...props} key={fieldAgent?.uuid}>
                        {fieldAgent?.name ?? ''}
                      </li>
                    )}
                    value={newFieldAgent}
                    onChange={setNewFieldAgent}
                    isLoading={fieldAgentListIsLoading}
                    error={fieldAgentListError}
                    required
                    renderInput={(value) => (params) => {
                      // console.log('RXD:FieldAgentReassignForm:renderInput', { params });
                      return (
                        <TextField
                          {...params}
                          value={value}
                          label="New Field Agent"
                        />
                      );
                    }}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="comment"
                      label="Comment"
                      variant="outlined"
                      type="text"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      multiline
                      required
                      rows={2}
                    />
                  </FormControl>

                  <Divider sx={{ my: 2 }} />

                  {formStatus === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ my: 2 }}
                      type="submit"
                      disabled={!newFieldAgent}
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};
