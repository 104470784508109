import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';
import { getInBuiltKeys } from './utils';

const generateInbuilt = (key) => ({
  roles: [],
  isCustom: false,
  key: key,
  type: 'superset',
  label: key,
  value: {
    id: '',
    isMobile: false,
    clauses: [],
  },
  createdAt: null,
  updatedAt: null,
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    configuration: [],
    loading: false,
    error: '',
    success: null,
  },
  reducers: {
    setConfiguration: (state, { payload }) => {
      const data = Array.from(payload);
      const savedKeys = [];
      for (const conf of data) {
        savedKeys.push(conf.key);
      }
      for (const id of getInBuiltKeys()) {
        if (savedKeys.indexOf(id) === -1) {
          data.push(generateInbuilt(id));
        }
      }
      data.sort((a, b) => a.label.localeCompare(b.label));

      state.configuration = data;
      state.loading = false;
      state.error = '';
    },
    updateConfiguration: (state, { payload }) => {
      const savedKeys = new Map();
      for (const conf of payload) {
        savedKeys.set(conf.key, conf);
      }
      const data = [...state.configuration];
      for (let i = 0; i < data.length; i++) {
        const conf = data[i];
        if (savedKeys.has(conf.key)) {
          data[i] = savedKeys.get(conf.key);
          savedKeys.delete(conf.key);
        }
      }
      for (const row of savedKeys.values()) {
        data.push(row);
      }
      data.sort((a, b) => a.label.localeCompare(b.label));

      state.configuration = data;
      state.loading = false;
      state.error = '';
    },
    deleteConfiguration: (state, { payload }) => {
      state.configuration = state.configuration.filter(
        (r) => r.uuid !== payload
      );
      state.error = '';
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = true;
      state.error = '';
      state.success = '';
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
      state.loading = false;
    },
  },
});

export const saveConfiguration = (data) => (dispatch) => {
  dispatch(setLoading(true));
  const action = data?.uuid
    ? getApi().patch(`/api/dashboard/${data.uuid}`, data)
    : getApi().post('/api/dashboard', data);
  return action
    .then((response) => {
      dispatch(updateConfiguration([response.data]));
      dispatch(setSuccess('Configuration has been saved'));
    })
    .catch((e) => {
      dispatch(setError(getApiError(e)));
    });
};

export const removeConfiguration = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  return getApi()
    .delete('/api/dashboard/' + uuid)
    .then(() => {
      dispatch(deleteConfiguration(uuid));
      dispatch(setSuccess('Configuration has been deleted'));
    })
    .catch((e) => {
      dispatch(setError(getApiError(e)));
    });
};

export const fetchDashboardConfiguration = () => (dispatch) => {
  dispatch(setLoading(true));
  return getApi()
    .get('/api/dashboard')
    .then((res) => {
      const response = res?.data?.data || [];
      dispatch(setConfiguration(response || []));
    });
};

export const {
  setConfiguration,
  updateConfiguration,
  deleteConfiguration,
  setLoading,
  setError,
  setSuccess,
} = dashboardSlice.actions;

export const dashboardConfigReducer = dashboardSlice.reducer;
