import {
  BooleanBubble,
  Error,
  Loading,
  getColumnVisibility,
  useFilters,
  useInitial,
} from '@/common';
import { ForRole } from '@/common/AuthorizedPage';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { getOnPageChange, getOnPageSizeChange } from '@/common/GridLoading';
import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { fieldAgentPositionListFetch } from '@/fieldAgent/fieldAgentPositionListSlice';
import {
  getPositionDropdown,
  limitHierarchyToMe,
  makeHierarchy,
} from '@/fieldAgent/utils';
import { getRoles } from '@/user/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeConfiguration,
  saveConfiguration,
  setError,
  setSuccess,
} from './dashboardSlice';
import { getVariables } from './utils';

export const DashboardConfiguration = () => {
  const [isEditing, setIsEditing] = useState('');
  const [filters, setFilters] = useFilters();
  const [page, perPage] = paginationValue(filters, 1, 15);
  const start = (page - 1) * perPage;

  const { configuration, loading, error } = useSelector(
    (state) => state.dashboardConfig
  );

  // console.log({ configuration });

  return (
    <ForRole roles="DASHBOARD_MANAGER">
      <DashboardForm
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        configuration={configuration}
      />

      {error && <Error error={error} />}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ pt: 1, pb: 2 }}
      >
        <Box sx={{ marginRight: 'auto' }}></Box>
        <Button
          disableElevation
          sx={{ textTransform: 'none' }}
          variant="contained"
          border="2px solid"
          size="medium"
          onClick={() => setIsEditing('add')}
        >
          Add Dashboard
        </Button>
      </Box>

      <OparetaTable
        rows={(configuration ?? []).slice(start, start + perPage)}
        getRowId={(row) => row?.key}
        loading={loading}
        page={page - 1}
        onCellClick={(param, e) => {
          setIsEditing(param.row.key);
        }}
        onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
        onPageChange={getOnPageChange(filters, setFilters, perPage)}
        columns={[
          {
            field: 'title',
            headerName: 'Title',
            valueGetter: (param) => param.row.label,
          },
          { field: 'type', headerName: 'Type' },
          {
            field: 'isCustom',
            type: 'boolean',
            headerName: 'Is Custom',
            maxWidth: 80,
            renderCell: (param) => <BooleanBubble value={param.row.isCustom} />,
          },
          {
            field: 'value.isMobile',
            type: 'boolean',
            headerName: 'Is Mobile',
            maxWidth: 80,
            renderCell: (param) => (
              <BooleanBubble value={param.row?.value?.isMobile ?? false} />
            ),
          },
          {
            field: 'value.showToolbar',
            type: 'boolean',
            headerName: 'Show Toolbar',
            maxWidth: 80,
            renderCell: (param) => (
              <BooleanBubble value={param.row?.value?.showToolbar ?? false} />
            ),
          },
          {
            field: 'embed',
            headerName: 'Embed ID',
            valueGetter: (param) => param.row?.value?.id,
          },
          {
            field: 'roles',
            headerName: 'Roles',
            renderCell: (param) => (param.row.roles ?? []).join(', '),
          },
          {
            field: 'positions',
            headerName: 'Positions',
            renderCell: (param) =>
              (param.row.positions ?? []).map((p) => p.name).join(', '),
          },
          { field: 'createdAt', headerName: 'Created At', type: 'dateTime' },
          { field: 'updatedAt', headerName: 'Updated At', type: 'dateTime' },
          {
            field: 'clauses',
            headerName: 'RLS',
            maxWidth: 150,
            renderCell: (param) =>
              (param.row?.value.clauses ?? []).join(', ') ?? '-',
          },
        ]}
        initialState={{
          columns: {
            columnVisibilityModel: getColumnVisibility(
              'DashboardConfiguration',
              {
                updatedAt: false,
                createdAt: false,
              }
            ),
          },
        }}
      />
    </ForRole>
  );
};

const DashboardForm = ({ isEditing, setIsEditing, configuration }) => {
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state.dashboardConfig
  );
  const { user } = useSelector((state) => state.auth);

  const {
    fieldAgentPositionList,
    isLoading: fieldAgentPositionListIsLoading,
    error: fieldAgentPositionListError,
  } = useSelector((state) => state.fieldAgentPositionList);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const [uuid, setUuid] = useState('');
  const [clause, setClause] = useState('');
  const [type, setType] = useState('');
  const [id, setId] = useState('');
  const [key, setKey] = useState('');
  const [label, setLabel] = useState('');
  const [title, setTitle] = useState('');
  const [roles, setRoles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [fieldAgentPositions, setFieldAgentPositions] = useState([]);
  const hasSubmitted = useRef('');

  useEffect(() => {
    if (isEditing === 'add' && key !== '') {
      setClause('');
      setType('superset');
      setUuid('');
      setId('');
      setKey('');
      setLabel('');
      setIsCustom(true);
      setIsMobile(true);
      setShowToolbar(false);
      setRoles([]);
      setPositions([]);
      hasSubmitted.current = '';
      dispatch(setError(''));
      dispatch(setSuccess(''));
    } else if (isEditing.length > 0 && key !== isEditing) {
      for (const conf of configuration) {
        if (conf.key === isEditing) {
          setClause((conf.value?.clauses ?? []).join(', '));
          setType(conf.type);
          setUuid(conf.uuid);
          setKey(conf.key);
          setId(conf.value?.id);
          setLabel(conf.label);
          setTitle(conf.label);
          setIsCustom(conf.isCustom ?? true);
          setIsMobile(conf.value?.isMobile ?? true);
          setShowToolbar(conf.value?.showToolbar ?? false);
          setRoles(conf.roles ?? []);
          setPositions(
            (conf.positions ?? []).map((p) => ({
              value: { id: p.id },
              label: p.name,
            }))
          );
          hasSubmitted.current = '';
          dispatch(setError(''));
          dispatch(setSuccess(''));
          break;
        }
      }
    }
  }, [id, isEditing, configuration]);

  useEffect(() => {
    if (loading && hasSubmitted.current === '') {
      hasSubmitted.current = 'loading';
    }
    if (!loading && hasSubmitted.current === 'loading') {
      if (error) {
        hasSubmitted.current = '';
      } else if (success) {
        setIsDeleting(false);
        setTimeout(() => {
          setIsEditing('');
          setKey('lol');
        }, 1000);
        hasSubmitted.current = '';
      }
    }
  }, [error, success, loading]);

  useInitial(() =>
    dispatch(
      fieldAgentPositionListFetch(
        new URLSearchParams(
          'pp=1:50&oo=id&ff=fap.id,fap.name,fap.parentId,fap.canSeeAllAgents'
        )
      )
    )
  );

  useEffect(() => {
    const modified = new Map(fieldAgentPositions.map((p) => [p.id, p]));
    for (let pos of fieldAgentPositionList) {
      if (!modified.has(pos.id)) {
        modified.set(pos.id, pos);
      }
    }

    if (modified.size != fieldAgentPositions.length) {
      const values = Array.from(modified.values());
      values.sort((a, b) => a?.name?.localeCompare(b?.name));
      setFieldAgentPositions(values);
    }
  }, [fieldAgentPositionList]);

  const positionList = useMemo(() => {
    const list = [];
    const [root] = makeHierarchy(fieldAgentPositions);
    const myHierarchy = limitHierarchyToMe(root);
    getPositionDropdown(
      true,
      list,
      user?.fieldAgent?.positionId,
      [myHierarchy],
      ''
    );
    return list;
  }, [fieldAgentPositions, user?.fieldAgent?.positionId]);

  useEffect(() => {
    let change = false;
    const newPositions = [...positions];
    // console.log({ newPositions, positionList });
    for (const p of positionList) {
      for (const position of newPositions) {
        if (
          p?.value?.id == position?.value?.id &&
          position?.label != p?.label
        ) {
          position.label = p.label;
          change = true;
        }
      }
    }
    if (change) {
      setPositions(newPositions);
    }
  }, [positions, positionList]);

  const w = (callback) => (e) => callback(e.target.value);

  const filterFieldAgentPositions = useCallback(
    (value, pp) => {
      const query = new URLSearchParams(
        'oo=id&ff=fap.id,fap.name,fap.parentId,fap.canSeeAllAgents'
      );
      query.set('pp', pp ?? '1:50');

      if (value) {
        query.set('name', `contains:${value}`);
      }
      dispatch(fieldAgentPositionListFetch(query));
    },
    [dispatch]
  );

  const handleSave = (e) => {
    e.preventDefault();
    const pv = positions.map((p) => ({ id: p.value.id, name: p.value.name }));
    // console.log({ positions, pv });
    const data = {
      roles,
      positions: pv,
      isCustom,
      key,
      type,
      label,
      value: {
        id,
        isMobile,
        showToolbar,
        clauses: clause ? [clause] : [],
      },
    };
    if (uuid) {
      data.uuid = uuid;
    }
    if (!key) {
      data.key = label.toLocaleLowerCase().replace(/\W+/g, '-');
    }
    dispatch(saveConfiguration(data));
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(removeConfiguration(uuid));
  };

  // console.log('RXD DashboardConfiguration: ', { success, error, hasSubmitted, loading, key });
  // console.log('RXD DashboardConfiguration: ', { positionList, positions });

  return (
    <Drawer
      open={isEditing.length > 0}
      onClose={(e) => {
        setIsEditing('');
        setKey('lol');
      }}
      anchor="bottom"
    >
      <Box component="form" onSubmit={handleSave} p={2} autoComplete="off">
        <Dialog open={isDeleting} onClose={(e) => setIsDeleting(false)}>
          <DialogTitle>Permanently Delete Dashboard {title}</DialogTitle>
          <Alert color="error">
            Proceeding will permanently delete '{title}' from this list of
            dashboards and the reports menu!
          </Alert>
          {loading ? (
            <Loading />
          ) : (
            <DialogActions
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Button
                onClick={(e) => setIsDeleting(false)}
                variant="contained"
                color="inherit"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="contained" color="error">
                Delete
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <Typography variant="h5" mb={2}>
          {isEditing === 'add' ? 'Add Dashboard' : `Edit Dashboard: ${title}`}
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        {error && <Error error={error} />}
        {success && <Alert severity="success">{success}</Alert>}

        <Grid container spacing={2} mt={2}>
          <Grid item md={5} xs={12}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                required
                fullWidth
                label="Title"
                helperText="The title to show in the menu"
                value={label}
                onChange={w(setLabel)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCustom}
                    onChange={(e) => setIsCustom(e.target.checked)}
                    name="custom"
                  />
                }
                label="For Dashboard: Turn on to show in the reports menu"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isMobile}
                    onChange={(e) => setIsMobile(e.target.checked)}
                    name="mobile"
                  />
                }
                label="For Mobile: Turn on to show in mobile"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showToolbar}
                    onChange={(e) => setShowToolbar(e.target.checked)}
                    name="toolbar"
                  />
                }
                label="Filter toolbar: Turn on to display the chart filter controls"
              />
            </FormControl>
            <Divider sx={{ mb: 4 }} />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    id="type"
                    label="Type"
                    variant="outlined"
                    required
                    value={type}
                    onChange={w(setType)}
                  >
                    <MenuItem value="superset">Superset</MenuItem>
                    <MenuItem value="tableau">Tableau</MenuItem>
                    <MenuItem value="iframe">IFrame</MenuItem>
                  </Select>
                  <FormHelperText>Source of the dashboard</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    required={isCustom}
                    label="Embed ID"
                    helperText="The superset or tableau ID to use"
                    value={id}
                    onChange={w(setId)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7} xs={12}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                multiline
                fullWidth
                rows={8}
                value={clause}
                onChange={w(setClause)}
                label="Row Level Security"
                helperText="RLS is where clause to limit data in report. This should be valid SQL"
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Autocomplete
            id="role"
            value={roles}
            options={getRoles()}
            multiple
            required
            fullWidth
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => setRoles(newValue)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label="Roles" fullWidth />
            )}
          />
          <FormHelperText>
            To view this dashboard user must have any of these roles
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <DynamicAutoComplete
            id="position"
            value={positions}
            options={positionList}
            multiple
            required
            fullWidth
            disableCloseOnSelect
            getOptionLabel={(option) => option?.label ?? ''}
            handleSearch={filterFieldAgentPositions}
            onChange={(newValue) => setPositions(newValue)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...props } = getTagProps({ index });
                return <Chip label={option.label} key={key} {...props} />;
              })
            }
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            renderInput={(value) => (params) => (
              <TextField {...params} label="Positions" fullWidth />
            )}
          />
          <FormHelperText>
            To view this dashboard user must have any of these positions
          </FormHelperText>
        </FormControl>

        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Clause Variables</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Variable</TableCell>
                    <TableCell>Meaning</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getVariables().map(([variable, meaning]) => (
                    <TableRow key={variable}>
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={(e) =>
                            navigator.clipboard
                              .writeText(variable)
                              .catch(console.error)
                          }
                        >
                          COPY
                        </Button>
                      </TableCell>
                      <TableCell>{variable}</TableCell>
                      <TableCell>{meaning}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Divider />

        {loading ? (
          <Loading />
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            my={2}
          >
            <Button variant="contained" sx={{ my: 2 }} type="submit">
              Save
            </Button>
            {uuid && isCustom && (
              <Button
                variant="outlined"
                color="error"
                sx={{ my: 2 }}
                onClick={(e) => setIsDeleting(true)}
              >
                Delete
              </Button>
            )}
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};
