import { dateFormat } from '@/common';

export const getTypes = () => ['sms', 'firebase', 'email'];

export const getTypeLabel = (type) => {
  switch (type) {
    case 'sms':
      return 'SMS';
    case 'firebase':
      return 'Push notification';
    case 'email':
      return 'Email';
    default:
      return type;
  }
};

export const getTypeBackground = (type) => {
  switch (type) {
    case 'sms':
      return '#DCF2EA';
    case 'firebase':
      return '#D6E0FF';
    case 'email':
      return '#E0B0FF';
    default:
      return '#edeff5';
  }
};

export const getTypeColor = (type) => {
  switch (type) {
    case 'sms':
      return '#317159';
    case 'firebase':
      return '#2952CC';
    case 'email':
      return '#673147';
    default:
      return '#474d66';
  }
};

export const getStatuses = () => ['pending', 'succeeded', 'failed'];

export const getStatusColor = (notificationSchedule) => {
  switch (notificationSchedule.status) {
    case 'pending':
      return '#4a4a4a';
    case 'succeeded':
      return '#317159';
    case 'failed':
      return '#bf1100';
    default:
      return 'white';
  }
};

export const getStatusBackground = (notificationSchedule) => {
  switch (notificationSchedule.status) {
    case 'pending':
      return '#ffcf44';
    case 'succeeded':
      return '#DCF2EA';
    case 'failed':
      return '#ffcec9';
    default:
      return 'darkgrey';
  }
};

export const getScheduledAt = (notificationSchedule) =>
  dateFormat(notificationSchedule?.scheduledAt) ?? '-';

export const getCreatedAt = (notificationSchedule) =>
  dateFormat(notificationSchedule?.createdAt) ?? '-';

export const getUpdatedAt = (notificationSchedule) =>
  dateFormat(notificationSchedule?.updatedAt) ?? '-';
