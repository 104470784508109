import { Error as ErrorAlert } from '@/common';
import { GridLoad } from '@/common/Loading';
import logo from '@/images/logo.svg';
import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { LoggedInRedirect } from './LoggedInRedirect';
import { tryFakeLogin } from './authSlice';

export const DebugLogin = () => {
  // console.log('RXD:DebugLogin');
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state.auth);
  const { uuid } = useParams();

  useEffect(() => {
    if (import.meta.env?.VITE_FAKE_LOGIN && user === null && uuid) {
      dispatch(tryFakeLogin(uuid));
    }
  }, [dispatch, uuid, user]);

  if (!import.meta.env?.VITE_FAKE_LOGIN) {
    // console.log('RXD:DebugLogin: Login');
    return <Navigate to="/" />;
  }

  if (user) {
    // console.log('RXD:DebugLogin: LoggedInRedirect');
    return <LoggedInRedirect defaultTo="/profile" />;
  }

  return (
    <>
      <Grid container spacing={0} direction="column" alignItems="center">
        <img alt="Opareta" src={logo} height={150} />
        <Typography variant="subtitle2">
          {error ? (
            <ErrorAlert error={error} />
          ) : (
            'Loading application state . . .'
          )}
        </Typography>
        <GridLoad />
      </Grid>
    </>
  );
};
