import { agentListFetch } from '@/agent/agentListSlice';
import {
  AppBar,
  Error,
  IS_MULTI_PHONE,
  IS_MULTI_UUID,
  Loading,
  dateFormat,
  makeUnique,
  useInitial,
} from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { DynamicSelect } from '@/common/DynamicSelect';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { expenseSave, resetState } from './expenseFormSlice';
import { expenseFetch } from './expenseSlice';
import { expenseTypeListFetch } from './expenseTypeListSlice';
import { getExpenseKinds, getPageTitle } from './utils';
import { captureEvent } from '@/firebase';

export const ExpenseForm = ({ mode }) => {
  // console.log('RXD:ExpenseForm');
  const dispatch = useDispatch();
  const { fieldAgentUuid, agentUuid, uuid } = useParams();

  // console.log('RXD:ExpenseForm', { fieldAgentUuid, agentUuid, uuid });

  const { expense, isLoading, error } = useSelector((state) => state.expense);

  const {
    expenseTypeList,
    isLoading: expenseTypeListIsLoading,
    error: expenseTypeListError,
  } = useSelector((state) => state.expenseTypeList);

  const {
    agentList,
    isLoading: agentListIsLoading,
    error: agentListError,
  } = useSelector((state) => state.agentList);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const { error: formError, status } = useSelector(
    (state) => state.expenseForm
  );

  const [expensedAt, setExpensedAt] = useState('');
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('');
  const [kind, setKind] = useState('');
  const [allowMultiple, setAllowMultiple] = useState(false);
  const [comment, setComment] = useState('');
  const [agents, setAgents] = useState([]);
  const [fieldAgents, setFieldAgents] = useState([]);

  const filterAgents = useCallback(
    (value, pp) => {
      const query = new URLSearchParams('oo=agentName&ff=a.uuid,a.agentName');
      query.set('pp', pp || '1:6');
      if (!allowMultiple && agentUuid) {
        setAgents([{ uuid: agentUuid }]);
        query.set('uuid', agentUuid);
      }
      if (value) {
        const isMultiPhone = IS_MULTI_PHONE.test(value);
        const isMultiUuid = IS_MULTI_UUID.test(value);
        if (isMultiPhone || isMultiUuid) {
          const label = isMultiPhone ? 'phoneNumber' : 'uuid';
          query.set(label, `in:${value}`);
        } else {
          query.set('agentName', `contains:${value}`);
        }
      }
      dispatch(agentListFetch(query));
    },
    [dispatch, allowMultiple, agentUuid]
  );

  const filterFieldAgents = useCallback(
    (value, pp) => {
      // console.log('RXD:filterFieldAgents', { value, fieldAgentUuid });
      const query = new URLSearchParams('oo=name&ff=fa.uuid,fa.name');
      query.set('pp', pp || '1:6');

      if (!allowMultiple && fieldAgentUuid) {
        setFieldAgents([{ uuid: fieldAgentUuid }]);
        query.set('fa.uuid', fieldAgentUuid);
      }
      if (value) {
        const isMultiPhone = IS_MULTI_PHONE.test(value);
        const isMultiUuid = IS_MULTI_UUID.test(value);
        if (isMultiPhone || isMultiUuid) {
          const label = isMultiPhone ? 'phoneNumber' : 'uuid';
          query.set(label, `in:${value}`);
        } else {
          query.set('name', `contains:${value}`);
        }
      }
      dispatch(fieldAgentListFetch(query));
    },
    [dispatch, allowMultiple, fieldAgentUuid]
  );

  useEffect(() => {
    if (uuid && uuid !== expense?.uuid && !isLoading && !error) {
      dispatch(resetState());
      dispatch(expenseFetch(uuid));
    }

    if (!allowMultiple) {
      if (
        mode === 'field-agents' &&
        fieldAgentList.length > 0 &&
        fieldAgents.length === 1 &&
        typeof fieldAgents[0] === 'object' &&
        fieldAgents[0]?.uuid &&
        !('name' in fieldAgents[0])
      ) {
        const items = fieldAgentList.filter(
          (x) => x.uuid === fieldAgents[0].uuid
        );
        if (items.length === 1) {
          setFieldAgents(items);
        }
      } else if (
        mode === 'agents' &&
        agentList.length > 0 &&
        agents.length === 1 &&
        typeof agents[0] === 'object' &&
        agents[0]?.uuid &&
        !('agentName' in agents[0])
      ) {
        const items = agentList.filter((x) => x.uuid === agents[0].uuid);
        console.log('agents', { items, agentList });
        if (items.length === 1) {
          setAgents(items);
        }
      }
    }
  }, [
    dispatch,
    expense,
    uuid,
    isLoading,
    error,
    agentList,
    agents,
    fieldAgentList,
    fieldAgents,
    allowMultiple,
    mode,
  ]);

  // initial data
  useInitial(() => {
    // console.log('RXD:ExpenseForm: initial');
    dispatch(expenseTypeListFetch(new URLSearchParams('pp=1:200&oo=name')));

    setExpensedAt(dateFormat(new Date(), 'YYYY-MM-DD'));
    setCurrency(import.meta.env?.VITE_CURRENCY_CODE ?? 'UGX');

    if (mode === 'agents') {
      filterAgents();
    } else if (mode === 'field-agents') {
      filterFieldAgents();
    }
  });

  // initial values
  useEffect(() => {
    if (uuid === expense?.uuid) {
      setType(expense?.type ?? '');
      setAmount(expense?.amount ? +expense.amount : '');
      setKind(expense?.kind ?? '');
      setComment(expense?.comment ?? '');
      setExpensedAt(
        dateFormat(
          expense?.expensedAt ? new Date(expense?.expensedAt) : new Date(),
          'YYYY-MM-DD'
        )
      );
    }
  }, [expense, uuid]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      expensedAt,
      currency,
      amount: +amount,
      type,
      kind,
      comment,
    };
    if (mode === 'agents') {
      data.agents = (agents ?? []).map(({ uuid }) => ({ uuid }));
    } else if (mode === 'field-agents') {
      data.fieldAgents = (fieldAgents ?? []).map(({ uuid }) => ({ uuid }));
    }
    dispatch(expenseSave({ mode, agentUuid, fieldAgentUuid }, uuid, data));
    captureEvent(`${(mode || '').toUpperCase()}_EXPENSE_FORM_SAVE`, {});
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        {uuid
          ? `Edit ${getPageTitle(mode)}${expense?.type ?? uuid}`
          : `Add Expense`}
      </AppBar>

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(expenseFetch(uuid))}
                />
              ) : isLoading ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="expensedAt"
                          label="Date"
                          variant="outlined"
                          type="date"
                          required
                          value={expensedAt}
                          helperText="All expenses are effected at 8:00 AM"
                          onChange={w(setExpensedAt)}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="amount"
                          label="Amount"
                          variant="outlined"
                          type="number"
                          required
                          value={amount}
                          onChange={w(setAmount)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {currency}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <DynamicSelect
                        label="Expense Type"
                        options={expenseTypeList}
                        renderOption={(expenseType) => (
                          <MenuItem
                            value={expenseType?.name}
                            key={expenseType?.name}
                          >
                            {expenseType?.name}
                          </MenuItem>
                        )}
                        value={type}
                        onChange={w(setType)}
                        isLoading={expenseTypeListIsLoading}
                        error={expenseTypeListError}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <InputLabel for="kind">Schedule</InputLabel>
                        <Select
                          labelId="kind"
                          label="Schedule"
                          variant="outlined"
                          value={kind}
                          required
                          onChange={w(setKind)}
                        >
                          {getExpenseKinds(expensedAt).map(
                            ([schedule, label]) => (
                              <MenuItem key={schedule} value={schedule}>
                                {label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {kind !== 'one-time' &&
                          expensedAt <
                            new Date().toISOString().substring(0, 10) && (
                            <FormHelperText>
                              Back-dating will effect new expenses at each of
                              times it is supposed to reoccur.
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      {mode === 'agents' && (
                        <DynamicAutoComplete
                          controlProps={{ sx: { mt: 1 } }}
                          options={makeUnique(
                            [...agentList, ...agents],
                            (x) => x.uuid
                          )}
                          multiple={allowMultiple}
                          isOptionEqualToValue={(option, value) =>
                            option?.uuid === value?.uuid
                          }
                          getOptionLabel={(option) => option?.agentName ?? ''}
                          handleSearch={filterAgents}
                          renderOption={(props, agent) => (
                            <li {...props} key={agent?.uuid}>
                              {agent?.agentName ?? ''}
                            </li>
                          )}
                          value={allowMultiple ? agents : agents[0] ?? ''}
                          disabled={!allowMultiple}
                          onChange={(value) => {
                            setAgents(Array.isArray(value) ? value : [value]);
                          }}
                          isLoading={agentListIsLoading}
                          error={agentListError}
                          required
                          renderInput={(value) => (params) => {
                            // console.log('RXD:ExpenseForm:renderInput', { params });
                            return (
                              <TextField
                                {...params}
                                value={value}
                                label="Agent"
                              />
                            );
                          }}
                        />
                      )}

                      {mode === 'field-agents' && (
                        <DynamicAutoComplete
                          controlProps={{ sx: { mt: 1 } }}
                          options={makeUnique(
                            [...fieldAgentList, ...fieldAgents],
                            (x) => x.uuid
                          )}
                          multiple={allowMultiple}
                          isOptionEqualToValue={(option, value) =>
                            option?.uuid === value?.uuid
                          }
                          getOptionLabel={(option) => option?.name ?? ''}
                          handleSearch={filterFieldAgents}
                          renderOption={(props, fieldAgent) => (
                            <li {...props} key={fieldAgent?.uuid}>
                              {fieldAgent?.name ?? ''}
                            </li>
                          )}
                          value={
                            allowMultiple ? fieldAgents : fieldAgents[0] ?? ''
                          }
                          disabled={!allowMultiple}
                          onChange={(value) => {
                            setFieldAgents(
                              Array.isArray(value) ? value : [value]
                            );
                          }}
                          isLoading={fieldAgentListIsLoading}
                          error={fieldAgentListError}
                          required
                          renderInput={(value) => (params) => {
                            // console.log('RXD:ExpenseForm:renderInput', { params });
                            return (
                              <TextField
                                {...params}
                                value={value}
                                label="Field Agent"
                              />
                            );
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        sx={{ my: 2 }}
                        control={<Switch checked={allowMultiple} />}
                        label={`Multiple ${
                          mode === 'agents' ? 'agents' : 'field agents'
                        }`}
                        onChange={(e) => setAllowMultiple(!allowMultiple)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ my: 2 }}>
                        <TextField
                          id="comment"
                          label="Comment"
                          variant="outlined"
                          type="text"
                          required
                          value={comment}
                          rows={3}
                          multiline
                          onChange={w(setComment)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {status === 'loading' ? (
                    <Loading />
                  ) : (
                    <Button variant="contained" sx={{ my: 2 }} type="submit">
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
