import { getApi } from '@/common';
import { fetchDashboardConfiguration } from '@/dashboardConfiguration/dashboardSlice';
import { requestPermission } from '@/firebase';
import { createSlice } from '@reduxjs/toolkit';
import { setUserProperties } from 'firebase/analytics';
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

let firebaseApp, store;

/** @type {import('firebase/auth').Auth} */
let auth;
let presistent = { subscribed: false, reloginRetries: 0 };
// console.log('RXD: ', { presistent });

export const injectFirebaseApp = (app, _store) => {
  if (!firebaseApp) {
    firebaseApp = app;
    // console.log('RXD:', { app });
    auth = getAuth(firebaseApp);
    store = _store;
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isInitializing: true,
    isLoading: false,
    isSuccess: false,
    error: '',
    user: null,
    idToken: '',
  },
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
      state.error = '';
      state.isSuccess = false;
    },
    setLoggedIn: (state, { payload }) => {
      const { user, idToken } = payload;
      state.email = '';
      state.password = '';
      state.isLoading = false;
      state.isInitializing = false;
      state.user = user;
      state.idToken = idToken;

      window?.localStorage?.setItem('user', JSON.stringify(user));
      window?.localStorage?.setItem('idToken', idToken);
    },
    setResetSent: (state) => {
      state.error = '';
      state.isLoading = false;
      state.isSuccess = true;
    },
    setLoginError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
      state.isInitializing = false;
    },
    setLogout: (state) => {
      state.email = '';
      state.password = '';
      state.isLoading = false;
      state.isInitializing = false;
      state.user = null;
      state.error = '';
      state.token = '';

      const hasUser =
        window?.localStorage?.getItem('user') ||
        window?.localStorage?.getItem('idToken');
      // console.log('RXD: setLogout()', { hasUser });
      if (hasUser) {
        // console.log('RXD: setLogout(): clear and signOut');
        window?.localStorage?.removeItem('user');
        window?.localStorage?.removeItem('idToken');
        signOut(auth);
      }
    },
  },
});

export const authLoginCheck = () => (dispatch) => {
  dispatch(setLoading());
  // console.log('RXD: authLoginCheck(1)');
  onAuthStateChanged(auth, (firebaseUser) => {
    if (firebaseUser != null) {
      if (!presistent.subscribed) {
        // console.log('RXD: authLoginCheck:subscribeIdTokenUpdates');
        presistent.subscribed = true;
        subscribeIdTokenUpdates(dispatch);
      }
      // console.log('RXD: authLoginCheck:refreshLocalUser');
      // console.log({ firebaseUser, auth });
      refreshLocalUser(dispatch, convertFirebaseUser(firebaseUser));
    } else {
      // console.log('RXD: authLoginCheck: no user', { firebaseUser });

      const idToken = store.getState().auth.idToken;
      const fakeLogin = import.meta.env?.VITE_FAKE_LOGIN;

      // console.log('RXD: authLoginCheck(2)', { idToken, fakeLogin });
      if (fakeLogin && idToken.length === 36) {
        dispatch(fetchDashboardConfiguration());
        return;
      } else {
        dispatch(setLogout());
      }
    }
  });
};

export const authLogin = (email, password) => (dispatch) => {
  // console.log('RXD: authLogin');
  dispatch(setLoading());
  // console.log('RXD: authLogin:setLoading()');
  signInWithEmailAndPassword(auth, email, password).catch((err) => {
    // console.log('RXD: authLogin:Error', { err });
    dispatch(setLoginError(err.message));
  });
};

export const authForgotPassword = (email) => (dispatch) => {
  dispatch(setLoading());
  const url = window?.location?.origin;
  sendPasswordResetEmail(auth, email, { url })
    .then(() => {
      dispatch(setResetSent());
    })
    .catch((err) => dispatch(setLoginError(err.message)));
};

export const authLogout = () => (dispatch) => dispatch(setLogout());

/**
 * Helper function that takes a Firebase user and tries to convert that to
 * a server user
 *
 * @param {*} firebaseUser
 * @returns {Promise<[idtoken:string, user:any]>}
 */
const convertFirebaseUser = (firebaseUser) => {
  // console.log('RXD: convertFirebaseUser');
  return firebaseUser.getIdToken().then((idToken) => {
    // console.log('RXD: convertFirebaseUser.getIdToken');
    return getApi()
      .get('/api/users/me', {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .then((res) => {
        return [idToken, res];
      });
  });
};

/**
 * Register a listener that updates the idtoken that the application uses
 * everytime firebase refreshs it's token
 *
 * @param {import('@reduxjs/toolkit').Dispatch} dispatch
 */
const subscribeIdTokenUpdates = (dispatch) => {
  // console.log('RXD: subscribeIdTokenUpdates.listen');
  onIdTokenChanged(auth, (firebaseUser) => {
    // console.log('RXD: subscribeIdTokenUpdates.onIdTokenChanged', firebaseUser);
    if (firebaseUser) {
      // console.log('RXD: subscribeIdTokenUpdates refreshLocalUser()');
      refreshLocalUser(dispatch, convertFirebaseUser(firebaseUser));
    } else {
      // console.log('RXD: subscribeIdTokenUpdates dispatch(setLogout())');
      dispatch(setLogout());
    }
  });
};

/**
 * Attempt to refresh the idToken on 401 errors
 *
 * @param {import('@reduxjs/toolkit').Dispatch} dispatch
 */
export const authRefresh = (dispatch) => {
  presistent.reloginRetries += 1;
  // console.log('RXD: authRefresh', auth.currentUser);
  if (auth.currentUser && presistent.reloginRetries < 2) {
    // console.log('RXD: authRefresh(): refreshLocalUser');
    return refreshLocalUser(dispatch, convertFirebaseUser(auth.currentUser));
  } else {
    // console.log('RXD: authRefresh(): dispatch(setLogout())');
    presistent.reloginRetries = 0;
    dispatch(setLogout());
  }
};

export const tryFakeLogin = (uuid) => (dispatch) => {
  getApi()
    .get('/api/users/me', {
      headers: { Authorization: `Bearer ${uuid}` },
    })
    .then((res) => {
      if (!res?.data) {
        dispatch(
          setLoginError(
            `Error could not impersonate user. Are you sure backend is correctly configured?`
          )
        );
        return;
      }
      const payload = { user: res?.data, idToken: uuid };
      // console.log('RXD:DebugLogin: setFakeUser', payload);
      dispatch(setLoggedIn(payload));
      dispatch(fetchDashboardConfiguration());
    })
    .catch((error) => {
      dispatch(setLoginError(`${error}`));
    });
};
const refreshLocalUser = (dispatch, futureUser) => {
  // console.log('RXD: refreshLocalUser');
  return futureUser
    .then(([idToken, response]) => {
      dispatch(setLoggedIn({ user: response.data, idToken }));
      dispatch(fetchDashboardConfiguration());
      // setUserProperties(user);
      requestPermission().then((token) => {
        getApi()
          .patch(`/api/users/${response.data.uuid}`, { fcmToken: token })
          .then();
      });
    })
    .catch((err) => dispatch(setLogout()));
};

export const {
  setLoading,
  setLoggedIn,
  setLoginError,
  setLogout,
  setResetSent,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
