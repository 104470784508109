import { FEATURES, hasFeature } from '@/common/features';

export const rolesToSet = (roles) => {
  return new Set((roles ?? '').split(',').filter((r) => r.length > 0));
};

export const hasAnyRoles = (userRoles, requiredRoles) => {
  const roles = rolesToSet(userRoles);
  const required = rolesToSet(requiredRoles);
  if (required.size === 0) {
    return true;
  }
  for (let role of roles) {
    if (required.has(role)) {
      return true;
    }
  }
  return false;
};

export const isAuthorized = (role, position, user, feature) => {
  const isRoleAllowed = hasAnyRoles(user?.role, role);
  const isPositionAllowed = hasAnyRoles(
    user?.fieldAgent?.positionId + '',
    position ?? ''
  );
  // console.log('RXD: isAuthorized', { role, position, user, isRoleAllowed, isPositionAllowed });
  return hasFeature(feature) && isPositionAllowed && isRoleAllowed;
};

export const defaultLoginPath = () => {
  if (hasFeature(FEATURES.TRANSACTION)) {
    return '/transactions';
  }
  return '/profile';
};
