import { isAuthorized } from '@/auth';
import { hasAnyRoles } from '@/auth/helpers';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { hasFeature } from './features';

export const AuthorizedPage = ({ role, feature }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  // console.log('RXD:AuthorizedPage', { role, user, feature, location });
  if (user && isAuthorized(role, '', user, feature)) {
    // console.log('RXD:AuthorizedPage', { role, user, feature, location });
    return <Outlet />;
  } else {
    const loginNext = user
      ? `login-next=${encodeURIComponent('/profile')}`
      : `login-next=${encodeURIComponent(location.pathname)}`;
    const prefix =
      location.search.length > 0
        ? `${location.search.replace(/&+$/, '')}&`
        : '?';
    const to = `/${prefix}${loginNext}`;
    return <Navigate to={to} />;
  }
};

export const ForFeature = ({ feature, children }) => {
  if (hasFeature(feature)) {
    return children;
  }
  return <></>;
};

export const ForRole = ({ roles, children }) => {
  const { user } = useSelector((state) => state.auth);
  if (hasAnyRoles(user?.role, roles)) {
    return children;
  }
  return <></>;
};
