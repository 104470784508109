export const DASHBOARD_CONFIGURATION = {
  MASTER: 'master',
  AGENTS: 'agents',
  MAP: 'map',
  FRAUD: 'fraud',
  FIELD_AGENTS: 'fieldAgents',
  CREDIT: 'credit',
  TRANSACTIONS: 'transactions',
  AGENT_TRANSACTION_TREND: 'agentTransactionTrend',
  FIELD_AGENT_REPORT: 'fieldAgentReport',
  RECONCILIATIONS: 'reconciliations',
  CUSTOMER_REGISTRATIONS: 'customer_registrations',
  CUSTOMER_ACTIVATIONS: 'customer_activations',
};

export const DASHBOARD_AGENT_DETAIL = 'agent-detail';
export const DASHBOARD_FIELD_AGENT_DETAIL = 'field-agent-detail';
export const DASHBOARD_MAP = 'map';
export const DASHBOARD_RECONCILIATIONS = 'reconciliations';

export const getInBuiltKeys = () => [
  DASHBOARD_AGENT_DETAIL,
  DASHBOARD_FIELD_AGENT_DETAIL,
  DASHBOARD_MAP,
  DASHBOARD_RECONCILIATIONS,
];

export const getVariables = () => [
  [
    '{{currentAgentUuid}}',
    "The current user's agent UUID if they are an agent",
  ],
  ['{{currentCreatedAt}}', 'The date user was created'],
  ['{{currentEmail}}', "The current user's email"],
  ['{{currentExternalId}}', "The current user's externalId e.g. Firebase UID"],
  [
    '{{currentFieldAgentCategory}}',
    "The current user's field agent category in commercial heirarchy",
  ],
  [
    '{{currentFieldAgentPosition}}',
    "The current user's field agent positionId in commercial heirarchy",
  ],
  [
    '{{currentFieldAgentUuid}}',
    "The current user's field agent UUID if they are a field agent",
  ],
  ['{{currentPhoneNumber}}', "The current user's phone number"],
  ['{{currentRoles}}', "The comma separated list of the current user's roles"],
  ['{{currentUpdatedAt}}', 'The date user was updated'],
  ['{{currentUserUuid}}', "The current user's UUID"],
];

export const filterCustomDashboards = (configuration) => {
  // console.log('RXD: filterCustomDashboards', { configuration });
  const menus = (configuration ?? [])
    .filter((d) => d.isCustom)
    .map((d) => ({
      role: (d.roles ?? []).join(','),
      position: (d.positions ?? [])
        .map((p) => p?.id)
        .filter((p) => p)
        .join(','),
      to: `/reports/custom/${d.key}`,
      title: d.label,
    }));
  // console.log('RXD: filterCustomDashboards', { menus });
  return menus;
};
