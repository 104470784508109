import { authLogout, isAuthorized } from '@/auth';
import { filterCustomDashboards } from '@/dashboardConfiguration/utils';
import { captureEvent } from '@/firebase';
import logo from '@/images/logo.svg';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { FEATURES } from './features';

const MenuLink = ({
  role,
  position,
  title,
  onClick = () => {},
  to,
  user,
  feature,
}) => {
  if (!isAuthorized(role, position, user, feature)) {
    return null;
  }
  const props = {};
  if (to) {
    props.to = to;
    props.component = RouterLink;
  }

  const handleClicked = (e) => {
    onClick(e);
  };

  return (
    <Button
      sx={{ color: '#2a2a2a', textTransform: 'none', fontSize: 16 }}
      {...props}
      onClick={handleClicked}
    >
      {title}
    </Button>
  );
};

export const AppBar = ({ children, actions }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(new Map());
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { configuration } = useSelector((state) => state.dashboardConfig);

  const canManageConfig = useMemo(
    () => user?.role?.includes('DASHBOARD_MANAGER'),
    [user]
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customDashboards = filterCustomDashboards(configuration);

  // console.log({ customDashboards });

  const menu = [
    {
      title: 'Transactions',
      children: [
        {
          role: 'AGENT,FIELD_AGENT',
          feature: FEATURES.TRANSACTION,
          to: '/transactions',
          title: 'Transactions',
        },
        {
          role: 'TRANSACTION_MANAGER',
          feature: FEATURES.FLOAT_EXCHANGE,
          to: '/exchange',
          title: 'Float Exchange',
        },
        {
          role: 'TRANSACTION_MANAGER',
          feature: FEATURES.USSD,
          to: '/ussd/actions',
          title: 'USSD Actions',
        },
        {
          role: 'TRANSACTION_MANAGER',
          feature: FEATURES.USSD,
          to: '/ussd/parsers',
          title: 'USSD Parsers',
        },
        {
          role: 'WALLET_MANAGER',
          feature: FEATURES.WALLET,
          to: '/wallets',
          title: 'Wallets',
        },
        {
          role: 'WALLET_MANAGER',
          feature: FEATURES.WALLET,
          to: '/transactions/requests',
          title: 'Requests',
        },
      ],
    },
    {
      title: 'Agents',
      children: [
        {
          role: 'AGENT,FIELD_AGENT,AGENT_MANAGER',
          feature: FEATURES.AGENT,
          to: '/agents',
          title: 'Agents',
        },
        {
          role: 'AGENT_MANAGER',
          feature: FEATURES.AGENT_COMPARE,
          to: '/agents/compare',
          title: 'Compare Agents',
        },
        {
          role: 'AGENT_MANAGER',
          feature: FEATURES.AGENT_EXPERIMENTATION,
          to: '/flags',
          title: 'Experimental Flags',
        },
        {
          role: 'AGENT_MANAGER',
          feature: FEATURES.AGENT_KNOWLEDGE_CENTER,
          to: '/agents/knowledge-center',
          title: 'Knowledge Center',
        },
      ],
    },
    {
      title: 'Field Agents',
      children: [
        {
          role: 'FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER',
          feature: FEATURES.FIELD_AGENT,
          to: '/field-agents',
          title: 'Team Members',
        },
        {
          role: 'FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER',
          feature: FEATURES.FIELD_AGENT,
          to: '/field-agents/positions',
          title: 'Manage Positions',
        },
        {
          role: 'FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER',
          feature: FEATURES.FIELD_AGENT,
          to: '/field-agents/assign',
          title: 'Assign Territories',
        },
        {
          role: 'FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER',
          feature: FEATURES.FIELD_AGENT_COMMISSION,
          to: '/field-agents/commissions',
          title: 'Field Agent Commissions',
        },
      ],
    },
    {
      title: 'Customers',
      children: [
        {
          role: 'CUSTOMER_MANAGER,CUSTOMER_VIEWER',
          feature: FEATURES.CUSTOMER_REGISTRATION,
          to: '/customers',
          title: 'Customers',
        },
      ],
    },
    {
      title: 'Float Control',
      children: [
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          to: '/float-cash/requests',
          title: 'Requests',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Reconciliations',
          to: '/float-cash/reconciliations',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Variances',
          to: '/float-cash/variances',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Disbursements',
          to: '/float-cash/disbursements',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Cash Transactions',
          to: '/float-cash/cash',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Thresholds',
          to: '/float-cash/thresholds',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          feature: FEATURES.FLOAT_CONTROL,
          title: 'Deleted transactions',
          to: '/float-cash/deleted',
        },
        {
          role: 'CASH_COLLECTOR,FLOAT_DISTRIBUTOR,RECONCILIATION_ADMIN,RECONCILIATION_VIEWER',
          title: 'Daily reports',
          to: '/float-cash/daily-reports',
        },
      ],
    },
    {
      title: 'Loans',
      children: [
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/products',
          title: 'Products',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/requests',
          title: 'Requests',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans',
          title: 'Disbursements',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/repayments',
          title: 'Repayments',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/contracts',
          title: 'Contracts',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/rules',
          title: 'Rules',
        },
        {
          role: 'LOAN_MANAGER,LOAN_REVIEWER,LOAN_VIEWER',
          feature: FEATURES.LOAN,
          to: '/loans/durations',
          title: 'Durations',
        },
      ],
    },
    {
      role: 'NOTIFICATION_MANAGER',
      feature: FEATURES.NOTIFICATION_CENTER,
      to: '/notifications',
      title: 'Notifications',
    },
    {
      title: 'Reports',
      children: customDashboards,
    },
    {
      role: 'AGENT_MANAGER,FIELD_AGENT_MANAGER,FIELD_AGENT_VIEWER',
      feature: FEATURES.REPORT,
      to: '/maps',
      title: 'Maps',
    },
  ];

  useEffect(() => {
    const title =
      Boolean(children) &&
      `${Array.isArray(children) ? children.join(' ') : children} - `;
    document.title = `${title}Opareta Dashboard`;
  }, [children]);

  const handleOpenMenu = (title) => (e) => {
    captureEvent(`MENU_OPEN`, { user, title, main: true });
    setAnchorEl(function (m) {
      m.set(title, e.target);
      const n = new Map(m);
      return n;
    });
  };

  const handleCloseMenu = (title) => () => {
    setAnchorEl(function (m) {
      m.delete(title);
      const n = new Map(m);
      return n;
    });
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.target);
    captureEvent(`MENU_OPEN`, { user, menu: 'user' });
  };

  const handleUserMenuEvents = (page) => {
    captureEvent(`MENU_CLICK`, { user, page, menu: 'user' });
  };

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleLogout = () => {
    dispatch(authLogout());
    handleUserMenuEvents('logout');
  };

  return (
    <>
      <MuiAppBar
        sx={{ borderBottom: 1, borderColor: '#edeff5' }}
        position="fixed"
        elevation={0}
      >
        <Toolbar sx={{ background: 'white' }}>
          <img alt="Opareta" src={logo} width={50} />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <Button
              startIcon={<MenuIcon />}
              onClick={(e) => setIsMenuOpen(!isMenuOpen)}
            >
              Menu
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: isMenuOpen ? 'flex' : 'none', md: 'flex' },
              justifyContent: {
                xs: isMenuOpen ? 'start' : 'center',
                md: 'center',
              },
              position: {
                xs: isMenuOpen ? 'fixed' : 'relative',
                md: 'relative',
              },
              top: { xs: isMenuOpen ? '59px' : '', md: '0' },
              backgroundColor: { xs: isMenuOpen ? 'white' : '', md: '' },
              left: { xs: isMenuOpen ? 0 : '', md: '' },
              right: { xs: isMenuOpen ? 0 : '', md: '' },
              flexDirection: { xs: isMenuOpen ? 'column' : '', md: 'row' },
              '& .MuiButton-text': {
                fontSize: '0.9rem',
                fontWeight: 600,
              },
              '& .MuiButton-root': {
                xs: isMenuOpen
                  ? {
                      justifyContent: isMenuOpen ? 'start' : 'center',
                    }
                  : {},
              },
            }}
          >
            {menu.map((topLevel, index) => {
              const key = `${topLevel.title}-${index}`;
              // console.log(`RXD: AppBar key: ${key}`, { topLevel });
              const children = (topLevel?.children ?? []).filter((item) =>
                isAuthorized(item.role, item?.position, user, item.feature)
              );

              if (children.length === 0 && !topLevel?.to) {
                return null;
              } else if (children.length === 1) {
                topLevel = children[0];
              } else if (children.length > 1) {
                return (
                  <div key={topLevel.title} id={topLevel.title}>
                    <MenuLink
                      user={user}
                      onClick={handleOpenMenu(topLevel.title)}
                      title={topLevel.title}
                    />
                    <Menu
                      sx={{ mt: '45px' }}
                      id={`menu-appbar-${topLevel.title
                        .toLocaleLowerCase()
                        .replace(/\W+/g, '-')}`}
                      anchorEl={anchorEl.get(topLevel.title)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorEl.get(topLevel.title))}
                      onClose={handleCloseMenu(topLevel.title)}
                      key={`${key}-oo`}
                    >
                      {children.map((item, index) => {
                        const childKey = `${key}-${item.to}-${index}`;
                        // console.log(`RXD: AppBar key: ${childKey}`, { item, index });
                        if (
                          isAuthorized(
                            item.role,
                            item?.position,
                            user,
                            item.feature
                          )
                        ) {
                          return (
                            <MenuItem
                              component={RouterLink}
                              to={item.to}
                              key={childKey}
                              id={childKey}
                              onClick={() =>
                                captureEvent(`MENU_CLICK`, {
                                  ...item,
                                  user,
                                  menu: item.title,
                                })
                              }
                            >
                              <Typography textAlign="center">
                                {item.title}
                              </Typography>
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Menu>
                  </div>
                );
              }

              return (
                <MenuLink
                  user={user}
                  {...topLevel}
                  key={key}
                  onClick={() =>
                    captureEvent(`MENU_CLICK`, {
                      ...topLevel,
                      user,
                      menu: topLevel.title,
                    })
                  }
                />
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Profile">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user?.avatar ? (
                  <Avatar alt={user?.fullName} src={user?.avatar} />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => handleUserMenuEvents('profile')}
                component={RouterLink}
                to="/profile"
              >
                <Typography textAlign="center">My Profile</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleUserMenuEvents('notifications')}
                component={RouterLink}
                to="/push_notifications"
              >
                <Typography textAlign="center">Notifications</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => handleUserMenuEvents('settings')}
                component={RouterLink}
                to="/settings"
              >
                <Typography textAlign="center">Settings</Typography>
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              <Divider />
              <MenuItem disabled>
                <Typography variant="subtitle2">
                  Version {import.meta.env?.VITE_VERSION}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Toolbar />
      <Grid
        container
        sx={{
          background: 'white',
          padding: '1rem',
          marginY: '1rem',
          borderRadius: '12px',
          border: '1px solid rgb(234, 234, 237)',
        }}
      >
        <Grid sx={{ flexGrow: 1 }}>
          <Typography
            variant="h5"
            sx={{
              mb: 0,
              fontSize: '1.25rem',
              fontWeight: '900',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              letterSpacing: 0,
              color: 'text.primary',
            }}
          >
            {children}
          </Typography>
        </Grid>
        {actions && <Grid sx={{ flexGrow: 0 }}>{actions}</Grid>}
      </Grid>
    </>
  );
};
