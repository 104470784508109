import { AppBar, Error, Loading, useInitial, useUpdated } from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { captureEvent } from '@/firebase';
import { FirebaseContext } from '@/firebaseContext';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { getStorage, ref } from 'firebase/storage';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  fieldAgentSave,
  resetState,
  uploadAttachmentFile,
} from './fieldAgentFormSlice';
import { fieldAgentListFetch } from './fieldAgentListSlice';
import { fieldAgentPositionListFetch } from './fieldAgentPositionListSlice';
import { fieldAgentFetch } from './fieldAgentSlice';
import {
  editCreatedAt,
  getPositionDropdown,
  isValidUrl,
  limitHierarchyToMe,
  makeHierarchy,
} from './utils';

export const FieldAgentForm = () => {
  // console.log('RXD:FieldAgentForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const firebaseApp = useContext(FirebaseContext);
  const storage = getStorage(firebaseApp);

  const { fieldAgent, isLoading, error } = useSelector(
    (state) => state.fieldAgent
  );
  const { user } = useSelector((state) => state.auth);

  const {
    fieldAgentPositionList,
    isLoading: fieldAgentPositionListIsLoading,
    error: fieldAgentPositionListError,
  } = useSelector((state) => {
    return state.fieldAgentPositionList;
  });

  const {
    fieldAgentList,
    isLoading: managersIsLoading,
    error: managersError,
  } = useSelector((state) => state.fieldAgentList);

  const { error: formError, status } = useSelector(
    (state) => state.fieldAgentForm
  );

  const [canSubmit, setCanSubmit] = useState(true);
  const [newUuid, setNewUuid] = useState('');
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [managerUuid, setManagerUuid] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [phoneNumber3, setPhoneNumber3] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [birthday, setBirthday] = useState('');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const [nationalIdPhotoUrl, setNationalIdPhotoUrl] = useState('');
  const [nationalIdBackPhotoUrl, setNationalIdBackPhotoUrl] = useState('');
  const [otherKycDocuments, setOtherKycDocuments] = useState([]);
  const [createdAt, setCreatedAt] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryMap, setCategoryMap] = useState(null);
  const [comments, setComments] = useState('');
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [managers, setManagers] = useState([]);

  const onDrop = (suffix, setUrl) => (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUrl(reader.result));
      const fileUuid = uuid ?? newUuid;
      const extension = file.type.split('/').pop();
      const storageRef = ref(
        storage,
        `field_agent/${fileUuid}-${suffix}.${extension}`
      );
      setCanSubmit(false);
      console.log('>>> Attempting to upload file: ', file);
      const fileFormData = new FormData();
      fileFormData.append('file', file);
      dispatch(uploadAttachmentFile(fileFormData))
        .then((response) => response.data.url)
        .catch((err) => {
          console.error(err);
          setUrl('');
        })
        .finally(() => {
          setCanSubmit(true);
        })
        .then(setUrl);
      reader.readAsDataURL(file);
    });
  };

  const { getRootProps: profileRootProps, getInputProps: profileInputProps } =
    useDropzone({
      multiple: false,
      maxFiles: 1,
      accept: { 'image/*': [] },
      onDrop: onDrop('profile', setProfilePhotoUrl),
    });

  const {
    getRootProps: nationalIdRootProps,
    getInputProps: nationalIdInputProps,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    accept: { 'image/*': [] },
    onDrop: onDrop('national-id', setNationalIdPhotoUrl),
  });

  const {
    getRootProps: nationalIdBackRootProps,
    getInputProps: nationalIdBackInputProps,
  } = useDropzone({
    multiple: false,
    maxFiles: 1,
    accept: { 'image/*': [] },
    onDrop: onDrop('national-id-back', setNationalIdBackPhotoUrl),
  });

  const { getRootProps: otherKycRootProps, getInputProps: otherKycInputProps } =
    useDropzone({
      multiple: false,
      maxFiles: 1,
      accept: { 'image/*': [], 'application/pdf': [] },
      onDrop: onDrop('other-docs', (url) => {
        const otherDocUrls = [].concat(...otherKycDocuments);
        otherDocUrls.push({
          name: `Kyc Document`,
          photoUrl: url,
        });
        setOtherKycDocuments(otherDocUrls);
      }),
    });

  useEffect(() => {
    if (uuid && uuid !== fieldAgent?.uuid && !isLoading && !error) {
      dispatch(resetState());
      setNewUuid(uuid);
      setProfilePhotoUrl('');
      setNationalIdPhotoUrl('');
      setNationalIdBackPhotoUrl('');
      setOtherKycDocuments([]);
      setComments('');
      setName('');
      setIdNumber('');
      setPhoneNumber('');
      setPhoneNumber2('');
      setPhoneNumber3('');
      setGender('');
      setEmail('');
      setBirthday('');
      setCategory('');
      setManagerUuid('');
      setCreatedAt('');
      setAddress('');
      dispatch(fieldAgentFetch(uuid));
    }
  }, [dispatch, fieldAgent, uuid, isLoading, error]);

  // initial data
  useInitial(() => {
    // console.log('RXD:FieldAgentForm: initial');
    dispatch(
      fieldAgentPositionListFetch(new URLSearchParams('pp=1:200&oo=name'))
    );
  });

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const updateCategoryValues = (positon) => {
    const { id } = positon;
    if (categoryList.length) {
      const found = categoryList.find((element) => element.value.id === id);
      return {
        label: found?.label,
        value: id,
      };
    }
  };

  // initial values
  useEffect(() => {
    if (uuid) {
      if (uuid === fieldAgent?.uuid) {
        setProfilePhotoUrl(fieldAgent?.profilePhotoUrl ?? '');
        setNationalIdPhotoUrl(fieldAgent?.nationalIdPhotoUrl ?? '');
        setNationalIdBackPhotoUrl(fieldAgent?.nationalIdBackPhotoUrl ?? '');
        setOtherKycDocuments(fieldAgent?.otherKycDocuments || []);
        setName(fieldAgent?.name ?? '');
        setAddress(fieldAgent?.address ?? '');
        setIdNumber(fieldAgent?.idNumber ?? '');
        setPhoneNumber(fieldAgent?.phoneNumber ?? '');
        setPhoneNumber2(fieldAgent?.phoneNumber2 ?? '');
        setPhoneNumber3(fieldAgent?.phoneNumber3 ?? '');
        setGender(fieldAgent?.gender ?? '');
        setEmail(fieldAgent?.email ?? '');
        setBirthday(fieldAgent?.birthday ?? '');
        setCategory(
          fieldAgent?.position ? updateCategoryValues(fieldAgent.position) : ''
        );
        setManagerUuid(
          fieldAgent?.manager
            ? {
                label: fieldAgent.manager.name,
                value: fieldAgent.manager.uuid,
              }
            : ''
        );
        setCreatedAt(editCreatedAt(fieldAgent) ?? '');
        setIsActive(fieldAgent?.isActive ?? false);
      }
    } else {
      setNewUuid(uuidv4());
      setCreatedAt(new Date().toISOString().substring(0, 10));
      setIsActive(true);
      dispatch(resetState());
    }
  }, [fieldAgent, uuid, categoryList]);

  useEffect(() => {
    if (category) {
      const position = categoryMap?.get(category.value);
      if (position) {
        dispatch(
          fieldAgentListFetch(
            new URLSearchParams(
              `positionsAbove=${position.id}&positionsBelow=${user.fieldAgentUuid}&isActive=true&ff=fa.name,fa.uuid&pp=1:300&e=1&oo=name`
            )
          )
        );
      }
    }
  }, [dispatch, category, categoryMap]);

  useEffect(() => {
    setManagers([]);
  }, [category]);

  const filterFieldAgents = useCallback(
    (value, pp) => {
      if (value || pp) {
        if (category) {
          const position = categoryMap?.get(category.value);
          if (position) {
            const query = new URLSearchParams(
              `positionsAbove=${position.id}&positionsBelow=${user.fieldAgentUuid}&isActive=true&ff=fa.name,fa.uuid&oo=name`
            );
            query.set('pp', pp || '1:20');
            if (value) {
              query.set('name', `contains:${value}`);
            }
            dispatch(fieldAgentListFetch(query));
          }
        }
      }
    },
    [dispatch, category, categoryMap]
  );

  useUpdated(() => {
    const list = [];
    const [root, map] = makeHierarchy(fieldAgentPositionList);
    const myHierarchy = limitHierarchyToMe(root);
    getPositionDropdown(true, list, null, [myHierarchy], '');
    setCategoryList(list);
    setCategoryMap(map);
  }, [fieldAgentPositionList]);

  const confirmDeleteDocument = (type, name, document) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      switch (type) {
        case 'id_front':
          setNationalIdPhotoUrl('');
          break;
        case 'id_back':
          setNationalIdBackPhotoUrl('');
          break;
        default:
          const otherDocUrls = [].concat(...otherKycDocuments);
          otherDocUrls.splice(otherDocUrls.indexOf(document), 1);
          setOtherKycDocuments(otherDocUrls);
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      name,
      gender,
      address,
      idNumber,
      category: categoryMap.get(category.value)?.name,
      position: { id: category.value },
      manager: { uuid: managerUuid.value },
      phoneNumber,
      phoneNumber2,
      phoneNumber3,
      email,
      birthday,
      profilePhotoUrl,
      nationalIdPhotoUrl,
      nationalIdBackPhotoUrl,
      isActive,
      createdAt,
      otherKycDocuments,
      comments: [comments],
    };
    dispatch(fieldAgentSave(uuid, data));
    captureEvent(
      uuid ? 'EDIT_FIELD_AGENT_FORM_SAVE' : 'ADD_FIELD_AGENT_FORM_SAVE',
      {}
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  const handleClose = () => setFullScreenUrl('');

  const memoizedManagerList = useMemo(() => {
    const updatedManagers = [];

    if (fieldAgentList.length > 0) {
      const existingUuids = [];
      for (let manager of managers) {
        existingUuids.push(manager.uuid);
      }
      const newManagers = [...managers];
      for (let fieldAgent of fieldAgentList) {
        if (!existingUuids.includes(fieldAgent.uuid)) {
          newManagers.push(fieldAgent);
        }
      }
      newManagers.sort((a, b) => a?.name?.localeCompare(b?.name));

      setManagers(newManagers);
    }

    for (let { uuid, name } of managers) {
      updatedManagers.push({
        value: uuid,
        label: name,
      });
    }

    return updatedManagers;
  }, [fieldAgentList]);

  const memoizedCategoryList = useMemo(() => {
    if (categoryList.length) {
      return categoryList.map(({ label, value }) => {
        return {
          value: value.id,
          label,
        };
      });
    }
    return [];
  }, [categoryList]);

  const ImagePreview = (props) => {
    const { url, name, type, document } = props;
    return (
      <Box
        sx={{
          height: 160,
          p: 2,
          border: 'solid',
          borderWidth: 3,
          borderStyle: 'dashed',
          borderColor: '#eee',
        }}
      >
        <Box height="100%" position="relative">
          <img
            alt=""
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              top: 0,
              left: 0,
              position: 'absolute',
              cursor: 'pointer',
            }}
            src={url}
            onClick={(e) => setFullScreenUrl(e.target.src)}
          />
          <Button
            variant="contained"
            color="warning"
            zIndex={9}
            onClick={(_) => confirmDeleteDocument(type, name, document)}
          >
            Delete
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <AppBar>
        {uuid ? `Edit : ${fieldAgent?.name ?? uuid}` : 'Add Team Member'}
      </AppBar>

      {formError && <Error error={formError} />}

      <>
        <Modal
          open={Boolean(fullScreenUrl)}
          onClose={handleClose}
          onClick={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Grid
            container
            spacing={0}
            direction="column"
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              sx={{ maxWidth: '100%', maxHeight: '100%' }}
              src={fullScreenUrl}
              alt={''}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Modal>
      </>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {error && uuid ? (
                <Error
                  error={error}
                  onRetry={() => dispatch(fieldAgentFetch(uuid))}
                />
              ) : isLoading ? (
                <Loading />
              ) : (
                <Box
                  component="form"
                  method="POST"
                  sx={{ p: 1 }}
                  onSubmit={handleSave}
                  autoComplete="off"
                >
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={2}>
                      <Typography variant="body1" sx={{ px: 2 }}>
                        Passport photo
                      </Typography>
                      <Box {...profileRootProps({ className: 'dropzone' })}>
                        <input {...profileInputProps()} />
                        <Avatar
                          alt="Profile Photo"
                          sx={{ width: 160, height: 160 }}
                          src={profilePhotoUrl ?? ''}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Grid container spacing={3}>
                        <Grid item md={6} direction="column">
                          <Typography variant="body1">
                            National ID Front
                          </Typography>
                          {isValidUrl(nationalIdPhotoUrl) ? (
                            <ImagePreview
                              type="id_front"
                              url={nationalIdPhotoUrl}
                              name="National ID Front"
                            />
                          ) : (
                            <Box
                              sx={{
                                height: 160,
                                p: 2,
                                border: 'solid',
                                borderWidth: 3,
                                borderStyle: 'dashed',
                                borderColor: '#eee',
                              }}
                              {...nationalIdRootProps({
                                className: 'dropzone',
                              })}
                            >
                              <input {...nationalIdInputProps()} />
                            </Box>
                          )}
                        </Grid>
                        <Grid item md={6} direction="column">
                          <Typography variant="body1">
                            National ID Back
                          </Typography>
                          {isValidUrl(nationalIdBackPhotoUrl) ? (
                            <ImagePreview
                              type="id_back"
                              url={nationalIdBackPhotoUrl}
                              name="National ID Back"
                            />
                          ) : (
                            <Box
                              sx={{
                                height: 160,
                                p: 2,
                                border: 'solid',
                                borderWidth: 3,
                                borderStyle: 'dashed',
                                borderColor: '#eee',
                              }}
                              {...nationalIdBackRootProps({
                                className: 'dropzone',
                              })}
                            >
                              <input {...nationalIdBackInputProps()} />
                            </Box>
                          )}
                        </Grid>
                        {otherKycDocuments.map((kycDocument, index) => {
                          return (
                            <Grid item md={6} direction="column">
                              <Typography variant="body1">
                                {kycDocument.name} {index + 1}
                              </Typography>
                              <ImagePreview
                                type="kyc_document"
                                url={kycDocument.photoUrl}
                                name={kycDocument.name}
                                document={kycDocument}
                              />
                            </Grid>
                          );
                        })}
                        <Grid item md={6} direction="column">
                          <Typography variant="body1">
                            Other kyc documents
                          </Typography>
                          <Box
                            sx={{
                              height: 160,
                              p: 2,
                              border: 'solid',
                              borderWidth: 3,
                              borderStyle: 'dashed',
                              borderColor: '#eee',
                            }}
                            {...otherKycRootProps({
                              className: 'dropzone',
                            })}
                          >
                            <input {...otherKycInputProps()} />
                            <Box
                              sx={{
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                height: '100%',
                              }}
                            ></Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography variant="subtitle2">
                        Drag and drop files here to upload, must be PDF or an
                        image
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          autoFocus
                          id="name"
                          label="Name"
                          helperText="Full name"
                          variant="outlined"
                          required
                          value={name}
                          onChange={w(setName)}
                        />
                      </FormControl>

                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isActive}
                              onChange={(e) => setIsActive(e.target.checked)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Active"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select
                          id="gender"
                          label="Gender"
                          variant="outlined"
                          value={gender}
                          onChange={w(setGender)}
                        >
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="birthday"
                          helperText="Birthday"
                          variant="outlined"
                          type="date"
                          value={birthday}
                          onChange={w(setBirthday)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="phoneNumber"
                          label="Phone number"
                          helperText={
                            phoneNumber && !/^\+\d{6,}$/.test(phoneNumber)
                              ? 'Please use international format +xxxyyyyy'
                              : 'Used by the field agent to sign into the field app'
                          }
                          variant="outlined"
                          value={phoneNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            const error =
                              value && !/^\+\d{6,}$/.test(value)
                                ? 'Please use international format +xxxyyyyy'
                                : '';

                            e.target.setCustomValidity(error);
                            setPhoneNumber(value);
                          }}
                          required={!email}
                          error={
                            !!phoneNumber && !/^\+\d{6,}$/.test(phoneNumber)
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="email"
                          label="Email"
                          helperText="Used by the field agent to sign into the dashboard"
                          variant="outlined"
                          type="email"
                          value={email}
                          onChange={w(setEmail)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="phoneNumber2"
                          label="Phone number"
                          helperText="Phone number alternate 1"
                          variant="outlined"
                          value={phoneNumber2}
                          onChange={w(setPhoneNumber2)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="phoneNumber3"
                          label="Phone number"
                          helperText="Phone number alternate 2"
                          variant="outlined"
                          value={phoneNumber3}
                          onChange={w(setPhoneNumber3)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <DynamicAutoComplete
                        label="Position"
                        value={category}
                        options={memoizedCategoryList}
                        onChange={(event) => {
                          setCategory(event);
                        }}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            option?.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        }
                        renderInput={(value) => (params) => {
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Position"
                              required
                            />
                          );
                        }}
                        isLoading={fieldAgentPositionListIsLoading}
                        error={fieldAgentPositionListError}
                        helperText={
                          (fieldAgent?.team ?? []).length > 0
                            ? 'Lowering this position may remove management of some team members'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <DynamicAutoComplete
                        label="Manager"
                        value={managerUuid}
                        options={memoizedManagerList}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            option?.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                        }
                        onChange={(value) => {
                          setManagerUuid(value);
                        }}
                        renderInput={(value) => (params) => {
                          return (
                            <TextField
                              {...params}
                              value={value}
                              label="Manager"
                            />
                          );
                        }}
                        handleSearch={filterFieldAgents}
                        isLoading={managersIsLoading}
                        error={managersError}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField
                          id="createdAt"
                          helperText="Starts At"
                          variant="outlined"
                          type="date"
                          required
                          value={createdAt}
                          onChange={w(setCreatedAt)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="id_number"
                        label="ID Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        value={idNumber}
                        onChange={w(setIdNumber)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        value={address}
                        onChange={w(setAddress)}
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    id="comments"
                    label="Comments"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={3}
                    sx={{ mt: 3 }}
                    value={comments}
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  />

                  {status === 'loading' ? (
                    <Loading />
                  ) : (
                    <Stack direction="row" spacing={3} mt={3}>
                      <Button
                        variant="contained"
                        sx={{ mb: 1, mt: 2 }}
                        type="submit"
                        disabled={!canSubmit}
                      >
                        Save
                      </Button>
                      {!canSubmit && (
                        <Box pt={1}>
                          <Typography variant="body2">
                            file upload in progress please wait...
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
