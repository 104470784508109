import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { saveSettings, settingsFetch } from '@/settings/settingsSlice';
import { BooleanBubble, Error, Loading } from '@/common';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { CardMedia, ListItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { getBalanceSummaryFormula } from '@/float-cash/util';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadAttachmentFile } from '@/fieldAgent/fieldAgentFormSlice';
import { useDropzone } from 'react-dropzone';
import { isValidUrl } from '@/fieldAgent/utils';

export const ModuleSettings = ({ embedded }) => {
  const { module, description } = embedded;
  const dispatch = useDispatch();
  const { isLoading, isSaving, settingItems, error } = useSelector(
    (state) => state.settings
  );
  const [updatedSettingItems, setUpdatedSettingItems] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [editMode, setEditMode] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [settingsBeingProcessed, setSettingsBeingProcessed] = useState([]);

  useEffect(() => {
    dispatch(settingsFetch(module));
    console.log('>>> Float cash setting module: ', module);
  }, []);

  useEffect(() => {
    setCanSave(checkCanSave());
  }, [updatedSettingItems, settingsBeingProcessed]);

  useEffect(() => {
    if (!isSaving && editMode) {
      setEditMode(false);
      setUpdatedSettingItems([]);
    }
  }, [isSaving]);

  const checkCanSave = () => {
    if (settingsBeingProcessed.length > 0) return false;
    let canSave =
      updatedSettingItems.filter(
        (settingItem) => (settingItem.value?.toString() ?? '').trim().length > 0
      ).length > 0;
    // Check setting item with valid file url
    for (const settingItem of updatedSettingItems) {
      if (settingItem.type === 'file_url' && !isValidUrl(settingItem.value)) {
        canSave = false;
        break;
      }
    }
    return canSave;
  };

  const onItemChanged = (settingItem, value) => {
    const id = settingItem.id;
    value = typeof value?.join === 'function' ? value.join(',') : value;
    console.log(`>>> Settings item changed: Id: ${id}, value: ${value} `);
    // const settingItems = [];
    const _updatedSettingItems = [].concat(...updatedSettingItems);
    const newSettingItem = {
      value: value,
      setting: {
        id: id,
      },
    };
    const index = _updatedSettingItems.indexOf(
      _updatedSettingItems.find((value) => value.setting.id === id)
    );
    if (index !== -1) {
      _updatedSettingItems[index] = newSettingItem;
    } else {
      _updatedSettingItems.push(newSettingItem);
    }
    setUpdatedSettingItems(_updatedSettingItems);
    console.log('>>> Updated setting items: ', _updatedSettingItems);
  };

  const toggleEditMode = () => (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  const getUpdatedItemById = (id) => {
    console.log('>>> Getting updated item by id: ', id);
    return updatedSettingItems.find((value) => value.setting?.id === id);
  };

  const handleSave = () => {
    dispatch(
      saveSettings(
        {
          settings: updatedSettingItems,
        },
        module
      )
    );
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{ pt: 1, pb: 2 }}
      >
        <Box sx={{ marginRight: 'auto' }}></Box>
        {!editMode && (
          <Button
            disableElevation
            variant="contained"
            component={RouterLink}
            type="button"
            onClick={toggleEditMode()}
          >
            Edit
          </Button>
        )}
      </Box>

      {error && (
        <Error
          error={`${error}`}
          onRetry={() => dispatch(settingsFetch(module))}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardContent>
            <List>
              {settingItems.map((item) => {
                return (
                  <ListItem sx={{ pl: 0, pr: 0 }}>
                    <Grid container>
                      <Grid item md={6} p={1}>
                        {!editMode ? (
                          <InfoItem settingItem={item} />
                        ) : (
                          <>
                            {item.type === 'array' && (
                              <ArraySettingItem
                                settingItem={item}
                                updatedSettingItems={updatedSettingItems}
                                getUpdatedItemById={getUpdatedItemById}
                                onItemChanged={onItemChanged}
                                editMode={editMode}
                              />
                            )}
                            {item.type === 'hours' && (
                              <HourSettingItem
                                settingItem={item}
                                updatedSettingItems={updatedSettingItems}
                                getUpdatedItemById={getUpdatedItemById}
                                onItemChanged={onItemChanged}
                                editMode={editMode}
                              />
                            )}
                            {item.type === 'boolean' && (
                              <BooleanSettingItem
                                settingItem={item}
                                updatedSettingItems={updatedSettingItems}
                                getUpdatedItemById={getUpdatedItemById}
                                onItemChanged={onItemChanged}
                                editMode={editMode}
                              />
                            )}
                            {item.type === 'json' && (
                              <JsonSettingItem
                                settingItem={item}
                                updatedSettingItems={updatedSettingItems}
                                getUpdatedItemById={getUpdatedItemById}
                                onItemChanged={onItemChanged}
                                editMode={editMode}
                              />
                            )}
                            {item.type === 'file_url' && (
                              <UrlSettingItem
                                settingItem={item}
                                updatedSettingItems={updatedSettingItems}
                                getUpdatedItemById={getUpdatedItemById}
                                onItemChanged={onItemChanged}
                                editMode={editMode}
                                settingsBeingProcessed={settingsBeingProcessed}
                                setSettingsBeingProcessed={
                                  setSettingsBeingProcessed
                                }
                              />
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item md={6} p={1}>
                        <Typography>{item.description}</Typography>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                  </ListItem>
                );
              })}
            </List>

            {editMode && (
              <Stack direction="row">
                <Button
                  disabled={isSaving || !canSave}
                  variant="contained"
                  type="button"
                  onClick={(_) => handleSave()}
                >
                  Save{' '}
                  {isSaving && <CircularProgress color="inherit" size={20} />}
                </Button>
                <Button
                  disabled={isSaving}
                  variant="outlined"
                  type="button"
                  onClick={toggleEditMode()}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

const InfoItem = (props) => {
  const { settingItem } = props;
  return (
    <Stack direction="column">
      <Typography>{settingItem.label}</Typography>
      <Box sx={{ overflow: 'hidden' }}>
        {settingItem.type === 'boolean' ? (
          <BooleanBubble value={settingItem.value} />
        ) : settingItem.type === 'file_url' ? (
          <Stack direction="column">
            <img src={settingItem.value} alt="" />
          </Stack>
        ) : settingItem.type === 'json' ? (
          <List>
            {getBalanceSummaryFormula(settingItem.value).map((value) => {
              return (
                <ListItem sx={{ pl: 0, pr: 0 }}>
                  <Typography fontWeight="bold" color="primary" mr={1}>
                    {value.key} {'=>'}
                  </Typography>{' '}
                  <Typography> {value.value}</Typography>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography fontWeight="bold">{settingItem.value}</Typography>
        )}
      </Box>
      <Divider sx={{ mt: 3 }} />
    </Stack>
  );
};

const ArraySettingItem = (props) => {
  const {
    settingItem,
    updatedSettingItems,
    onItemChanged,
    getUpdatedItemById,
    editMode,
  } = props;
  const [updatedValue, setUpdatedValue] = useState('');
  const [initialDataSet, setInitialDataSet] = useState(false);
  useEffect(() => {
    if (settingItem != null) {
      if (!initialDataSet) {
        setUpdatedValue(settingItem.value);
        setInitialDataSet(true);
      } else {
        setUpdatedValue(getUpdatedItemById(settingItem.id)?.value);
      }
    }
  }, [settingItem, updatedSettingItems]);
  return (
    <TextField
      autoFocus
      label={settingItem?.label}
      type="text"
      fullWidth
      value={updatedValue}
      disabled={!editMode}
      placeholder="Comma separated list of items"
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        onItemChanged(settingItem, e.target.value);
      }}
    />
  );
};

const HourSettingItem = (props) => {
  const {
    settingItem,
    updatedSettingItems,
    onItemChanged,
    getUpdatedItemById,
    editMode,
  } = props;
  const [updatedValue, setUpdatedValue] = useState('');
  const [initialDataSet, setInitialDataSet] = useState(false);
  useEffect(() => {
    if (settingItem != null) {
      if (!initialDataSet) {
        setUpdatedValue(settingItem.value);
        setInitialDataSet(true);
      } else {
        setUpdatedValue(getUpdatedItemById(settingItem.id)?.value);
      }
    }
  }, [settingItem, updatedSettingItems]);
  return (
    <TextField
      label={settingItem.label}
      fullWidth
      value={updatedValue}
      type="number"
      disabled={!editMode}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        onItemChanged(settingItem, e.target.value);
      }}
    />
  );
};

const BooleanSettingItem = (props) => {
  const {
    settingItem,
    updatedSettingItems,
    onItemChanged,
    getUpdatedItemById,
    editMode,
  } = props;
  const [updatedSettingOption, setUpdatedSettingOption] = useState(false);
  const [initialDataSet, setInitialDataSet] = useState(false);
  useEffect(() => {
    if (settingItem != null) {
      if (!initialDataSet) {
        setInitialDataSet(true);
        setUpdatedSettingOption(settingItem.value);
      } else {
        const updatedItem = getUpdatedItemById(settingItem.id);
        if (typeof updatedItem?.value === 'boolean') {
          setUpdatedSettingOption(updatedItem.value);
        }
      }
    }
  }, [settingItem, updatedSettingItems]);
  return (
    <FormControlLabel
      disabled={!editMode}
      control={<Switch checked={updatedSettingOption} />}
      label={settingItem.label}
      onChange={(e) => onItemChanged(settingItem, !updatedSettingOption)}
    />
  );
};

const JsonSettingItem = (props) => {
  const {
    settingItem,
    updatedSettingItems,
    onItemChanged,
    getUpdatedItemById,
    editMode,
  } = props;
  const [updatedValue, setUpdatedValue] = useState('');
  const [initialDataSet, setInitialDataSet] = useState(false);
  useEffect(() => {
    if (settingItem != null) {
      if (!initialDataSet) {
        setUpdatedValue(settingItem.value);
        setInitialDataSet(true);
      } else {
        setUpdatedValue(getUpdatedItemById(settingItem.id)?.value);
      }
    }
  }, [settingItem, updatedSettingItems]);
  return (
    <TextField
      autoFocus
      label={settingItem?.label}
      type="text"
      fullWidth
      minRows={3}
      multiline
      value={updatedValue}
      disabled={!editMode}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        onItemChanged(settingItem, e.target.value);
      }}
    />
  );
};

const UrlSettingItem = (props) => {
  const {
    settingItem,
    updatedSettingItems,
    onItemChanged,
    settingsBeingProcessed,
    setSettingsBeingProcessed,
    getUpdatedItemById,
  } = props;
  const [updatedValue, setUpdatedValue] = useState('');
  const [initialDataSet, setInitialDataSet] = useState(false);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const onDrop = (suffix, setUrl) => (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUrl(reader.result));
      const extension = file.type.split('/').pop();
      console.log('>>> Attempting to upload file: ', file);
      const fileFormData = new FormData();
      setUploading(true);
      fileFormData.append('file', file);
      dispatch(uploadAttachmentFile(fileFormData))
        .then((response) => response.data.url)
        .catch((err) => {
          console.error(err);
          setUrl('');
        })
        .finally(() => {
          setUploading(false);
        })
        .then(setUrl);
      reader.readAsDataURL(file);
    });
  };
  const { getRootProps: fileUrlRootProps, getInputProps: fileUrlInputProps } =
    useDropzone({
      multiple: false,
      maxFiles: 1,
      accept: { 'image/*': [] },
      onDrop: onDrop(settingItem.module, (fileUrl) => {
        onItemChanged(settingItem, fileUrl);
      }),
    });
  useEffect(() => {
    if (settingItem != null) {
      if (!initialDataSet) {
        setUpdatedValue(settingItem.value);
        setInitialDataSet(true);
      } else {
        setUpdatedValue(getUpdatedItemById(settingItem.id)?.value);
      }
    }
  }, [settingItem, updatedSettingItems]);

  useEffect(() => {
    console.log('>>> Setting uploading: ', uploading);
    const _settingsBeingProcessed = [].concat(...settingsBeingProcessed);
    if (uploading && !_settingsBeingProcessed.includes(settingItem.key)) {
      _settingsBeingProcessed.push(settingItem.key);
    } else {
      _settingsBeingProcessed.splice(
        _settingsBeingProcessed.indexOf(settingItem.key),
        1
      );
    }
    setSettingsBeingProcessed(_settingsBeingProcessed);
  }, [uploading]);
  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        p: 2,
        border: 'solid',
        borderWidth: 3,
        borderStyle: 'dashed',
        borderColor: '#eee',
        cursor: 'pointer',
      }}
      {...fileUrlRootProps({
        className: 'dropzone',
      })}
    >
      <input {...fileUrlInputProps()} />
      <Box sx={{ position: 'relative', height: 140 }}>
        <CardMedia
          component="img"
          height="100%"
          image={updatedValue}
          alt=""
          sx={{ objectFit: 'contain', position: 'relative' }}
        />
        {uploading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loading size={80} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
