import { debounce } from '@/common';
import { FormControl, FormHelperText, List } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Loading } from '.';
import { paginationValue } from './filter';

export const DynamicListBox = forwardRef((props, r) => {
  const { isLoading, onScroll, children, ...other } = props;
  const ref = useRef(null);
  const currentPage = useRef(0);
  const handleScroll = useCallback(
    (event) => {
      const { scrollTop, clientHeight } = event.target;
      let childHeight = 0,
        calculatedPage = 1;
      if (event.target.children.length > 0) {
        childHeight = event.target.children[0].clientHeight;
      }

      if (childHeight > 0) {
        const optionsVisibleInPage = clientHeight / childHeight;
        const allOptionsLoaded = scrollTop / childHeight;
        calculatedPage = Math.ceil(allOptionsLoaded / optionsVisibleInPage);
      }

      if (currentPage.current != calculatedPage) {
        currentPage.current = calculatedPage;
        onScroll(calculatedPage);
      }
    },
    [currentPage, onScroll]
  );

  // useEffect(() => {
  //   if (ref && ref.current && isLoading) {
  //     ref.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [isLoading]);

  return (
    <List ref={ref} {...other} onScroll={handleScroll}>
      {children}
      <li style={{ display: isLoading ? 'block' : 'none' }}>
        <Loading size={25} />
      </li>
    </List>
  );
});

export const DynamicAutoComplete = ({
  handleSearch,
  getOptionLabel,
  isOptionEqualToValue,
  isLoading,
  controlProps,
  error,
  options,
  filterOptions,
  value,
  defaultValue,
  renderInput,
  renderOption,
  renderTags,
  onChange,
  onBlur,
  multiple,
  helperText,
  required,
  disabled,
}) => {
  const pagination = useRef('1:20');
  const prevInputValue = useRef('');
  const firstOpen = useRef(true);
  const [inputValue, setInputValue] = useState('');
  controlProps = typeof controlProps === 'object' ? controlProps : {};

  const handleScroll = useCallback(
    (p) => {
      let [_, pp] = paginationValue(
        new URLSearchParams('?pp=' + pagination.current)
      );
      pagination.current = p + ':' + pp;
      const doSearch = debounce((v) => handleSearch('', v), 500);
      doSearch(pagination.current);
    },
    [handleSearch]
  );

  useEffect(() => {
    if (handleSearch && inputValue && prevInputValue.current !== inputValue) {
      pagination.current = '1:20';
      const doSearch = debounce(
        (value) => handleSearch(value, pagination.current),
        500
      );
      prevInputValue.current = inputValue;
      doSearch(inputValue, pagination.current);
    }
  }, [inputValue, handleSearch]);

  useEffect(() => {
    if (options.length === 0 || firstOpen.current) {
      firstOpen.current = false;
      handleScroll(1);
    }
  }, [options]);

  return (
    <Box sx={{ position: 'relative' }}>
      <FormControl fullWidth error={Boolean(error)} {...controlProps}>
        <Autocomplete
          autoComplete
          includeInputInList
          openOnFocus={true}
          filterSelectedOptions
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          options={options}
          multiple={multiple}
          required={required}
          value={value}
          defaultValue={defaultValue}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, value) => onChange(value)}
          onBlur={onBlur}
          renderOption={renderOption}
          disabled={disabled}
          renderTags={renderTags}
          renderInput={renderInput(inputValue)}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          ListboxComponent={DynamicListBox}
          ListboxProps={{ onScroll: handleScroll, isLoading }}
        />
        {(Boolean(error) || Boolean(helperText)) && (
          <FormHelperText>{error ? error : helperText}</FormHelperText>
        )}
      </FormControl>
      <Box sx={{ position: 'absolute', right: '-10px', top: '-8px' }}>
        {isLoading && <Loading size={25} css="margin-top:20px;flex-grow:0;" />}
      </Box>
    </Box>
  );
};
