import { hasAnyRoles } from '@/auth/helpers';
import { AppBar, Error, Loading } from '@/common';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import Card from '@mui/material/Card';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const CustomReport = () => {
  const { key } = useParams('key');
  const { configuration, loading } = useSelector(
    (state) => state.dashboardConfig
  );
  const { user } = useSelector((state) => state.auth);
  const conf = useMemo(() => {
    for (const conf of configuration) {
      if (conf.key === key) {
        return conf;
      }
    }
  }, [key, configuration]);

  const userRoles = user?.role ?? '';
  const position = (user?.fieldAgent?.positionId ?? '') + '';
  const requiredRoles = (conf?.roles ?? []).join(',');
  const requiredPositions = (conf?.positions ?? []).map((p) => p?.id).join(',');
  const isAllowed =
    conf &&
    hasAnyRoles(userRoles, requiredRoles) &&
    hasAnyRoles(position, requiredPositions);

  // console.log({ key, conf, isAllowed, userRoles, requiredRoles });

  return (
    <>
      <AppBar>{conf ? conf.label : 'Not found'}</AppBar>

      {loading ? (
        <Loading />
      ) : !conf ? (
        <Card>
          <Error error="Report not found! Please ask data administrator for assistance" />
        </Card>
      ) : !isAllowed ? (
        <Card>
          <Error error="You don't have required permissions! Please ask data administrator for assistance" />
        </Card>
      ) : (
        <EmbeddedChart theKey={key} />
      )}
    </>
  );
};
